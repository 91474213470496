<template>
  <div class="logo-component-container py-16">
    <h2>Recuperar contraseña</h2>
    <p>
      Te llegará un correo electrónico con un código, el cual no debes compartir con nadie, ese código te servirá para el siguiente paso en el cual tendrás que ingresarlo para registrar una nueva contraseña
    </p>
    <div class="login-form-container">
      <div class="login-form">
        <transition name="fadeHeight" mode="out-in">
          <alert-box
            v-if="error != '' || (Array.isArray(error) && error.length != 0)"
            class="login-alert-box my-2"
            type="error"
            :title="'Datos erróneos, favor de verificar'"
            :errors="error"
          />
        </transition>
        <input-text
          class="mt-3"
          placeholder="Dirección de Correo"
          icon="mdi-at"
          @text="(value) => { form.email = value }"
          :error="errorEmail"
        />
        <button-component
          @whenClick="submit()"
          :loading="!canSubmit"
        >
          Enviar correo
        </button-component>
      </div>
    </div>
    <div class="login-link-group">
      <router-link to="/login" class="login-link link-text--text">¿Ya tienes una cuenta?</router-link>
      <router-link to="/new-password" class="login-link link-text--text"><strong>¿Ya tienes un código?</strong></router-link>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import InputText from '@/components/form/InputText.vue';
import AlertBox from '@/components/form/AlertBox.vue';
import Button from '@/components/form/Button.vue';

export default {
  name: 'login',
  components: {
    'input-text': InputText,
    'button-component': Button,
    'alert-box': AlertBox,
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
        remember: false,
      },
      errorEmail: false,
      show_pass: false,
      error: '',
      canSubmit: true,
    };
  },
  methods: {
    ...mapActions('main', [
      'forgetPassword',
    ]),
    validateEmail: (email) => {
      const regex = /\S+@\S+\.\S+/;
      return regex.test(email);
    },
    submit() {
      if (!this.canSubmit) {
        return;
      }
      // Validation
      this.setError();
      if (!this.validateEmail(this.form.email)) {
        this.setError('Has ingresado una dirección de correo no válida!');
        return;
      }
      
      // Login Call
      this.canSubmit = false;
      this.forgetPassword(this.form.email)
        .then(() => {
          alert('Correo enviado exitosamente, revisar su bandeja de correos.')
          this.$router.push('new-password');
        })
        .catch(err => console.log(err));
    },
    setError(error) {
      if (!error) {
        this.error = [];
      } else {
        this.error.push(error);
      }
    }
  },
  watch: {
    'form.email'() {
      if (this.form.email != '') {
        this.errorEmail = !this.validateEmail(this.form.email);
      } else {
        this.errorEmail = false;
      }
    }
  }
};
</script>

<style scoped>
.logo-component-container {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 500px;
  width: 100%;
}
.login-link-group {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.login-link {
  cursor: pointer;
  text-decoration: underline;
}
.login-link:not(:last-child) {
  margin-right: 1em;
}
.login-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
  width: 100%;
}
.login-form .login-margin {
  margin-bottom: 1em;
}
.login-remember {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.login-remember div {
  width: 50%;
}
.login-checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.login-forgot {
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}
.login-alert-box {
  margin-bottom: 0px;
}
.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.2s;
  max-height: 230px;
}
.fadeHeight-enter,
.fadeHeight-leave-to
{
  opacity: 0;
  max-height: 0px;
}
</style>
